import { useScroll } from "@use-gesture/react";
import { useState } from "react";
import throttle from "lodash.throttle";

interface ScrollTracking {
  percentsToTrack: number[];
  callback: ({ percentReached }: { percentReached: number }) => void;
  throttledInterval?: number;
}

/**
 * Hook to track the user's scroll position and trigger a callback when the user has scrolled to a certain percentage scroll point
 *
 *
 * @param {{ percentsToTrack, callback }} - array of percentage whole numbers to trigger and the corresponding callback function
 * @param {{ throttledInterval }} - the interval to throttle the scroll handler (default: 100ms)
 *
 * @note - This will only trigger each scroll point once. After all points have been hit, the hook will stop tracking scroll position.
 * @note - If the user is navigated to the middle of the page, the hook will trigger the callback for all points that have been passed.
 * @note - Used as a base scroll tracking hook. If you want to track page scrolling events in a standard way,
 *         use the usePageScrollTracking hook instead.
 */
const useScrollTracking = ({
  percentsToTrack,
  callback,
  throttledInterval = 100,
}: ScrollTracking) => {
  const [remainingScrollPoints, setRemainingScrollPoints] = useState(
    [...percentsToTrack].sort((a, b) => a - b)
  );

  const isClient = typeof window !== "undefined";
  const hasRemainingScrollPoints = remainingScrollPoints.length > 0;
  const shouldWatchScrollPoints = isClient && hasRemainingScrollPoints;

  // Needed for SSR -> Sets the scrollRef to the window object once defined
  const windowTarget = isClient ? window : null;

  // Fired by the useScroll hook when the user scrolls
  const scrollHandler = ({ xy: [, scrollY] }) => {
    const percentScrolled = calculatePercentScrolled({ scrollY });
    const scrolledPastPoints = remainingScrollPoints.filter(
      (scrollPoint) => percentScrolled >= scrollPoint
    );
    const hasScrolledPastPoints = scrolledPastPoints.length > 0;

    if (hasScrolledPastPoints) {
      // remove the points we have scrolled past from the remaining points
      const newRemainingScrollPoints = remainingScrollPoints.filter(
        (scrollPoint) => !scrolledPastPoints.includes(scrollPoint)
      );
      setRemainingScrollPoints(newRemainingScrollPoints);

      // trigger the callback for each scroll point that was passed
      scrolledPastPoints.forEach((scrollPoint) =>
        callback({ percentReached: scrollPoint })
      );
    }
  };

  // Throttle the scroll handler to prevent it from firing too often
  const throttledScrollHandler = throttle(scrollHandler, throttledInterval);

  useScroll(throttledScrollHandler, {
    enabled: shouldWatchScrollPoints,
    target: windowTarget,
  });
};

/**
 * Calculates the percent of the page that has been scrolled by the user
 *
 * @param {{ scrollY }} - the current scroll position
 * @returns { number } - the percent of the page that has been scrolled (0 - 100)
 */
const calculatePercentScrolled = ({ scrollY }: { scrollY: number }): number => {
  const footerHeight = document.getElementById("footer")?.clientHeight ?? 0;
  const scrollHeight = document.documentElement.scrollHeight;
  const innerHeight = window.innerHeight;
  const totalHeight = scrollHeight - innerHeight - footerHeight;

  return (scrollY / totalHeight) * 100;
};

export default useScrollTracking;
