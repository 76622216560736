export const isValidEmail = (email: string) => {
  const emailRegex = new RegExp(
    "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
  );
  return emailRegex.test(email);
};

export const isValidUUID = (uuid: string) => {
  const uuidRegex = new RegExp(
    "^[\\da-f]{8}-[\\da-f]{4}-[1-5][\\da-f]{3}-[89ab][\\da-f]{3}-[\\da-f]{12}$",
    "i"
  );
  return uuidRegex.test(uuid);
};
