interface FiftyFiftySectionProps {
  children?: React.ReactNode;
  dark?: boolean;
  className?: string;
}

const FiftyFiftySection = (props: FiftyFiftySectionProps) => {
  const darkClasses = props.dark ? "bg-gray-900 text-gray-400" : "";
  return (
    <div
      className={`md:grid lg:grid-cols-2 gap-x-12 ${
        darkClasses ? darkClasses : ""
      } ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
};

export default FiftyFiftySection;
