import useToc from "@lib/hooks/use-toc";
import { useEffect, useState } from "react";

const TableOfContents = ({
  selector,
  title,
  children,
  className = "top-[54px] max-w-[256px]",
  observeRootMargin = "-64px 0% 0% 0%",
  excludeClass = "no-toc",
}: {
  selector: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  observeRootMargin?: string;
  excludeClass?: string;
}) => {
  const { headings } = useToc(selector, excludeClass);

  // Visible headings are any heading that are currently visible.
  const [visibleHeadings, setVisibleHeadings] = useState([]);

  // Last active ID is the last heading that became visible. If there are no headings
  // currently visible, we keep the last active ID selected.
  const [lastActiveId, setLastActiveId] = useState(null);

  // Choose the visible ID that comes first in the headings order to be the active
  // one.
  const activeId = visibleHeadings.sort((a, b) => {
    const indexA = headings.findIndex((heading) => heading.id === a);
    const indexB = headings.findIndex((heading) => heading.id === b);

    return indexA - indexB;
  })?.[0];

  // Handle keeping track of which headings are visible, and which was last to
  // become active.
  useEffect(() => {
    const handleObserver = (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setVisibleHeadings((prev) => [...prev, entry.target.id]);
          setLastActiveId(entry.target.id);
        } else {
          setVisibleHeadings((prev) => {
            const index = prev.indexOf(entry.target.id);
            if (index !== -1) {
              const spliced = [...prev];
              spliced.splice(index, 1);
              return spliced;
            }
            return prev;
          });
        }
      });
    };
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: observeRootMargin,
    });
    const headings = Array.from(document.querySelectorAll(selector)).filter(
      (element) =>
        excludeClass ? !element.classList.contains(excludeClass) : true
    );
    headings.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [selector, observeRootMargin]);

  return (
    <div
      className={`sticky border-y border-keyline-1 py-4 flex-col gap-2 hidden md:flex ${className}`}
    >
      {title && <h4 className="text-xl small-caps text-blue-600">{title}</h4>}
      <ul className="flex flex-col gap-2 text-gray-500">
        {headings.map((heading) => (
          <li
            key={heading.id}
            // We only care about h2s and h3s.
            className={`${
              heading.type === "H3" ? "pl-6" : "pl-2"
            } border-l-2 hover:text-gray-600 ${
              activeId === heading.id ||
              (!activeId && heading.id === lastActiveId)
                ? "border-blue-600 text-gray-900"
                : "border-white"
            }`}
          >
            <a href={`#${heading.id}`}>{heading.title}</a>
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
};

export default TableOfContents;
