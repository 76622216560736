const ContentLayoutWithSidebar = ({ children }) => {
  return (
    <div className="flex-col md:flex-row flex justify-between my-4 md:my-10 md:gap-24 scroll-smooth">
      <div className="">{children[0]}</div>
      {/* The top padding here puts it at the same level as the TOC, which
        needs top padding because it is sticky. */}
      <div className="basis-[768px] flex-grow-0 md:pt-4">{children[1]}</div>
    </div>
  );
};

export default ContentLayoutWithSidebar;
