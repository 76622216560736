import ImageWithFallback from "@components/common/image-with-fallback";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Events from "@lib/constants/events";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import eventTracker from "@lib/tracking/event-tracker";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import {
  AssetType,
  getImageUrl,
  getRecommenderUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import Link from "next/link";

const VsPageCtaCard = (props: {
  productCategory: ProductCategoryConfig;
  trackingInfo?: string;
  className?: string;
}) => {
  const href = getUnderConstruction(props.productCategory, "landingPages")
    ? undefined
    : getRecommenderUrl(props.productCategory);

  const image = {
    src: getImageUrl(
      props.productCategory.heroImage.src,
      AssetType.Image,
      props.productCategory.heroImage.dimensions
    ),
    alt: `Image of ${props.productCategory.label}`,
    ...props.productCategory.heroImage.dimensions,
  };

  const name =
    props.productCategory.name === "headphones"
      ? props.productCategory.name
      : props.productCategory.noun;

  return (
    <Link
      href={href}
      onClick={() =>
        eventTracker.track(Events.VsPageGetRecsClick, {
          category: props.productCategory.name,
          identifier: `card block cta${
            props.trackingInfo ? " " + props.trackingInfo : ""
          }`,
        })
      }
    >
      <div
        className={`break-inside-avoid flex flex-col gap-3 relative w-full h-full bg-blue-600 p-4 rounded-lg items-center ${
          props.className ? props.className : ""
        }`}
      >
        <ImageWithFallback
          className="object-contain lg:mx-auto max-h-32"
          {...image}
        />
        <div className="flex font-bold text-xl font-serif mb-16 text-white text-center">
          Let Us Help Find Your Perfect&nbsp;
          {capitalizeFirstLetter(name)}
        </div>
        {href && (
          <div className="border flex gap-2 items-center justify-center max-w-[250px] border-blue-500 py-1.5 px-3 sm:px-4 rounded-lg absolute bottom-6 xs:bottom-3 right-[50%] text-md text-blue-700 font-semibold translate-x-[50%] w-[90%] lg:w-auto whitespace-nowrap bg-white">
            Find your new&nbsp;{name}
            <ArrowRightCircleIcon className="h-4 shrink-0" />
          </div>
        )}
      </div>
    </Link>
  );
};

export default VsPageCtaCard;
