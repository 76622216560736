enum StatusMessageType {
  Error = "error",
  Ok = "ok",
  Warning = "warning",
}

interface StatusMessageProps {
  type: StatusMessageType;
  children: React.ReactNode;
}

const StatusMessage = (props: StatusMessageProps) => {
  const backgrounds = {
    [StatusMessageType.Error]: "bg-red-400",
    [StatusMessageType.Ok]: "bg-green-500",
    [StatusMessageType.Warning]: "bg-orange-600",
  };

  return (
    <div
      className={`${
        backgrounds[props.type]
      } text-white my-2 p-2 rounded-md font-bold text-center`}
    >
      {props.children}
    </div>
  );
};

export const ErrorMessage = (props: { children: React.ReactNode }) => (
  <StatusMessage type={StatusMessageType.Error}>{props.children}</StatusMessage>
);

export const OkMessage = (props: { children: React.ReactNode }) => (
  <StatusMessage type={StatusMessageType.Ok}>{props.children}</StatusMessage>
);

export const WarningMessage = (props: { children: React.ReactNode }) => (
  <StatusMessage type={StatusMessageType.Warning}>
    {props.children}
  </StatusMessage>
);
