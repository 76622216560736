import React from "react";
import {
  Brand,
  ProductCategoryConfig,
} from "@lib/model/product-category-config";
import { IFaq, IMediaHighlight } from "@lib/model/contentful";
import { Product } from "@lib/model/product";

export type ProductCategoryContextType = {
  productCategoryConfig: ProductCategoryConfig;
  apiDomain: string;
  faqs?: IFaq[];
  mediaHighlights: IMediaHighlight[];
  getBrand: (label: string) => Brand;
  getProductBrands: (products: Product[]) => Brand[];
  getBrandsCount: () => number;
};

export const ProductCategoryContext =
  React.createContext<ProductCategoryContextType>(null);

const ProductCategoryContextProvider = ({
  children,
  productCategoryConfig,
  faqs,
  mediaHighlights,
}: {
  children: React.ReactNode;
  productCategoryConfig: ProductCategoryConfig;
  faqs?: IFaq[];
  mediaHighlights?: IMediaHighlight[];
}) => {
  const apiDomain = process.env.NEXT_PUBLIC_API_URL;

  /**
   * Given an array of brands for a category, get the one you need (case insensitive).
   */
  const getBrand = (label: string) =>
    (productCategoryConfig.brands || []).find(
      (brand) => brand.label.toLowerCase() === label.toLowerCase()
    );

  const getBrandsCount = () => (productCategoryConfig.brands || []).length;

  /**
   * Gets (unique) brand records that match a set of product's manufacturer values.
   */
  const getProductBrands = (products: Product[]) => {
    const uniqueBrandNames = Array.from(
      new Set(products.map((product) => product.manufacturer))
    );

    return uniqueBrandNames
      .map((brandName) => getBrand(brandName))
      .filter((brand) => !!brand);
  };

  return (
    <ProductCategoryContext.Provider
      value={{
        productCategoryConfig,
        apiDomain,
        faqs,
        mediaHighlights,
        getBrand,
        getProductBrands,
        getBrandsCount,
      }}
    >
      {children}
    </ProductCategoryContext.Provider>
  );
};

export default ProductCategoryContextProvider;
