import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  OkMessage,
  ErrorMessage,
} from "@components/form-element/status-message";
import { isValidEmail } from "@lib/utilities/validators";
import LoadingIcon from "@components/svgs/loading-icon";
import { PrimaryButton } from "@components/common/button";

const EmailForm = ({
  inlineBtn = false,
  btnText = "Join waitlist",
  submit,
  msgSuccess = "Thank you for subscribing!",
  msgError = "We were unable to subscribe you to the list. Please try again later.",
}: {
  inlineBtn?: boolean;
  btnText?: string;
  submit: (email: string, captchaCode: string) => Promise<boolean>;
  msgSuccess?: string;
  msgError?: string;
}) => {
  const [email, setEmail] = useState(null);
  const [status, setStatus] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);

  const recaptchaRef = useRef(null);

  const onReCAPTCHAChange = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined, indicating that the reCAPTCHA
    // was expired, return early.
    if (!captchaCode) {
      return;
    }

    try {
      const success = await submit(email, captchaCode);
      setStatus(success);
    } catch (e) {
      console.error(e);
      setStatus(false);
    }

    setLoading(false);

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const signup = async (event) => {
    event.preventDefault();
    if (!email) {
      setValidationError("Please provide an email address.");
      return;
    }

    if (!isValidEmail(email)) {
      setValidationError("Enter in the format: name@example.com");
      return;
    }

    setValidationError(null);
    setLoading(true);

    recaptchaRef.current.execute();
  };

  const recaptchaSiteKey =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_DUMMY ||
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

  return (
    <form onSubmit={signup}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={recaptchaSiteKey}
        onChange={onReCAPTCHAChange}
      />
      {!status && (
        <>
          {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
          <div className={`flex ${!inlineBtn ? "flex-col" : ""} gap-2`}>
            <input
              className={`rounded-lg border-gray-500 placeholder-gray-400 ${
                email ? "text-gray-900" : "text-gray-400"
              } font-semibold leading-5 py-3 px-5 w-full`}
              type="email"
              placeholder="Enter email address"
              onChange={(event) => setEmail(event.target.value)}
              disabled={loading}
            />
            <PrimaryButton
              type="submit"
              variant="solid"
              className={`gap-2 ${!inlineBtn && "w-full"}  whitespace-nowrap`}
              onClick={(event) => {
                event.preventDefault();
                signup(event);
              }}
            >
              {btnText}
              {loading && <LoadingIcon />}
            </PrimaryButton>
          </div>
        </>
      )}

      {status === true && <OkMessage>{msgSuccess}</OkMessage>}
      {status === false && <ErrorMessage>{msgError}</ErrorMessage>}
    </form>
  );
};

export default EmailForm;
