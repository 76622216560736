import { IAuthorFields } from "@lib/model/contentful";
import { getUrlForCmsAsset } from "@lib/utilities/client-utilities";
import ImageWithFallback from "./image-with-fallback";

type AuthorCardProps = {
  author: IAuthorFields;
  className?: string;
  title: string;
};

const AuthorCard = (props: AuthorCardProps) => {
  const author = props.author;
  return (
    <div className={`flex gap-4 ${props.className}`}>
      <ImageWithFallback
        src={getUrlForCmsAsset(author.picture, { w: 36, h: 36 })}
        className="h-9 rounded-2xl"
        alt={author.picture.fields.description || ""}
      />
      <div>
        <div className="font-bold leading-none">{author.name}</div>
        <div className="text-sm text-gray-500 grow mt-1 capitalize">
          {props.title}
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;
