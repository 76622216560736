import {
  AssetType,
  canonicalize,
  getImageUrl,
} from "@lib/utilities/shared-utilities";
import Head from "next/head";

const Meta = ({
  title,
  description,
  url,
  image,
  type = "website",
  children,
  canonicalUrl,
}: {
  title: string;
  description: string;
  url: string;
  image?: string;
  type?: "article" | "website";
  children?: React.ReactNode;
  canonicalUrl?: string;
}) => {
  const completeUrl = canonicalize(url);

  const completeCanonicalUrl = canonicalUrl && canonicalize(canonicalUrl);

  const defaultImage = getImageUrl(
    "perfectrec-logo-square.png",
    AssetType.Image
  );
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={completeUrl} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta name="twitter:image" content={image || defaultImage} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {canonicalUrl && <link rel="canonical" href={completeCanonicalUrl} />}
      {children}
    </Head>
  );
};

export default Meta;
