import Link from "next/link";
import { Breadcrumb } from "../../../shared/src/model/breadcrumb";

// Based on https://tailwindui.com/components/application-ui/navigation/breadcrumbs#component-30bf6b10109ab7c77e4a5ddf3e9506a8
const Breadcrumbs = ({
  pages,
  className = "my-4 md:my-8",
  removeLastHref = false,
}: {
  pages: Breadcrumb[];
  className?: string;
  removeLastHref?;
}) => {
  return (
    <nav
      className={`flex text-gray-500 justify-center ${className}`}
      aria-label="Breadcrumb"
    >
      <ol role="list" className="flex items-center space-x-1 sm:space-x-4">
        <li>
          <div>
            <Link href="/">Home</Link>
          </div>
        </li>
        {pages.map((page, i) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {page.href && !(removeLastHref && i === pages.length - 1) ? (
                <Link
                  href={page.href}
                  className="ml-4"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              ) : (
                <span className="ml-2 sm:ml-4">{page.name}</span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
