import { Asset } from "contentful";
import { getUrlForCmsAsset } from "@lib/utilities/client-utilities";
import ImageWithFallback from "./image-with-fallback";

const ContentHero = (props: {
  imageAsset: Asset;
  title: string;
  date?: string;
  children?: React.ReactNode;
}) => {
  const displayW = 768;

  const image = props.imageAsset?.fields.file.details.image;
  const imageW = image?.width;
  const imageH = image?.height;

  return (
    <div className="flex flex-col gap-6 max-w-screen-md mx-auto">
      <div className="basis-1/2">
        <h1
          data-testid="content-heading"
          className="text-left mt-0 text-3xl md:text-4xl"
        >
          {props.title}
        </h1>
      </div>
      {props.children}
      {props.imageAsset && (
        <ImageWithFallback
          src={getUrlForCmsAsset(props.imageAsset, { w: displayW })}
          alt={props.imageAsset.fields.description}
          className="rounded-2xl"
          height={Math.round(displayW / (imageW / imageH))}
          width={displayW}
        />
      )}
    </div>
  );
};

export default ContentHero;
