import eventTracker, { Events } from "@lib/tracking/event-tracker";
import useScrollTracking from "./use-scroll-tracking";

interface PageScrollTracking {
  page: string;
  percentsToTrack?: number[];
}

const PAGE_PERCENTS_TO_TRACK = [25, 50, 75, 100];

/**
 * Hook used to send an event to track the position of the user's scroll on a page by name
 *
 * @param {{ page, percentsToTrack }} - the page name and optionally explicit the percents to track
 * @note - the default percents to track are [25, 50, 75, 100]
 */
export const usePageScrollTracking = ({
  page,
  percentsToTrack = PAGE_PERCENTS_TO_TRACK,
}: PageScrollTracking) => {
  useScrollTracking({
    percentsToTrack,
    callback: ({ percentReached }) => {
      eventTracker.track(Events.PageScroll, {
        page,
        percent: percentReached,
      });
    },
  });
};
