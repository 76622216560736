import EventTracker, { Events } from "@lib/tracking/event-tracker";
import EmailForm from "./email-form";

const MailchimpForm = ({
  tags = [],
  inlineBtn = false,
  btnText = "Join waitlist",
}: {
  tags?: string[];
  inlineBtn?;
  btnText?;
}) => {
  const submit = async (email, captchaCode) => {
    const response = await fetch("/api/subscribe", {
      body: JSON.stringify({ email, captchaCode, tags }),
      method: "POST",
    });
    if (response.status === 200) {
      EventTracker.track(Events.SubscribedToList, { email, tags });
      return true;
    }
    return false;
  };

  return <EmailForm {...{ submit, inlineBtn, btnText }} />;
};

export default MailchimpForm;
