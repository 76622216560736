const QuoteIcon = ({ className }) => (
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.3634 22H25.4789V11.2308H20.8394C21.093 9.69231 21.7521 8.35897 22.8169 7.23077C23.8817 6.10256 25.2761 5.33333 27 4.92308V0C23.1465 0.820511 20.231 2.5641 18.2535 5.23077C16.3268 7.89744 15.3634 11.2821 15.3634 15.3846V22ZM0 22H10.0394V11.2308H5.4C5.65352 9.69231 6.31268 8.35897 7.37746 7.23077C8.44225 6.10256 9.83662 5.33333 11.5606 4.92308V0C7.75775 0.820511 4.8676 2.5641 2.89014 5.23077C0.96338 7.89744 0 11.2821 0 15.3846V22Z"
      fill="#9CA3AF"
    />
  </svg>
);

export default QuoteIcon;
