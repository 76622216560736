import Link from "next/link";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

/**
 * A single pager link
 */
const PagerLink = ({
  children,
  disabled,
  href,
  selected,
  className,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  href?: string;
  selected?: boolean;
  className?: string;
}) => {
  const selectedColors =
    "bg-blue-600 text-white border-blue-600 hover:bg-blue-700";
  const baseClasses =
    "flex justify-center p-3 border border-keyline-1 rounded-md";

  return (
    <>
      {!disabled ? (
        <Link
          href={href}
          scroll={false}
          className={`${baseClasses} ${
            selected ? selectedColors : "hover:bg-gray-50"
          } ${className || ""}`}
        >
          {children}
        </Link>
      ) : (
        <div className={`${baseClasses}`}>{children}</div>
      )}
    </>
  );
};

const Pager = ({
  currentPage,
  totalPages,
  numberOfLinks = 9, // Use an odd number here.
  getLink,
  className,
}: {
  currentPage: number;
  totalPages: number;
  numberOfLinks?: number;
  getLink: (page) => string;
  className?: string;
}) => {
  const linkPrev = currentPage - 1 < 1 ? null : getLink(currentPage - 1);
  const linkNext =
    currentPage + 1 > totalPages ? null : getLink(currentPage + 1);
  let includeLastPage = false;
  let includeFirstPage = false;
  const getIconClasses = (disabled) =>
    `w-6 h-6 ${disabled && "text-keyline-1"}`;

  const pageRange = middleOutPageRange(numberOfLinks, totalPages, currentPage);
  if (pageRange.length < 2) {
    return null;
  }
  if (pageRange[pageRange.length - 1] != totalPages) {
    pageRange.pop();
    includeLastPage = true;
  }
  if (pageRange[0] != 1) {
    pageRange.shift();
    includeFirstPage = true;
  }

  return (
    <div className={`flex gap-2 my-4 ${className || ""}`}>
      <PagerLink disabled={!linkPrev} href={linkPrev} className="flex-grow-0">
        <ArrowLeftCircleIcon className={getIconClasses(!linkPrev)} />
      </PagerLink>
      {includeFirstPage && (
        <>
          <PagerLink href={getLink(1)} selected={currentPage === 1} key={1}>
            1
          </PagerLink>
          <div className="mt-auto">...</div>
        </>
      )}
      {pageRange.map((page) => (
        <PagerLink
          href={getLink(page)}
          selected={currentPage === page}
          key={page}
        >
          {page}
        </PagerLink>
      ))}
      {includeLastPage && (
        <>
          <div className="mt-auto">...</div>
          <PagerLink
            href={getLink(totalPages)}
            selected={currentPage === totalPages}
            key={totalPages}
          >
            {totalPages}
          </PagerLink>
        </>
      )}
      <PagerLink disabled={!linkNext} href={linkNext} className="flex-grow-0">
        <ArrowRightCircleIcon className={getIconClasses(!linkNext)} />
      </PagerLink>
    </div>
  );
};

/**
 * Starting with the current page and a desired odd number of links, generate a
 * range of numbers that represent a valid set of pages for a pager component.
 */
const middleOutPageRange = (
  numberOfLinks: number,
  totalPages: number,
  currentPage: number
) => {
  const pageRange = [currentPage];

  const up = () => {
    const max = Math.max(...pageRange);

    if (max + 1 <= totalPages) {
      pageRange.push(max + 1);
      return true;
    }
  };

  const down = () => {
    const min = Math.min(...pageRange);

    if (min - 1 >= 1) {
      pageRange.unshift(min - 1);
      return true;
    }
  };

  let dir = 1;
  while (pageRange.length < numberOfLinks) {
    const beforeLength = pageRange.length;

    // We alternate trying to go up or down. In either case, if it can't do its
    // primary operation (e.g. it's too close to the top or bottom), it will spend
    // its cycle adding a page in the other direction.
    if (dir === 1) {
      if (!up()) {
        down();
      }
    } else if (dir === -1) {
      if (!down()) {
        up();
      }
    }

    // If it couldn't add to either the top or the bottom, we are done.
    if (beforeLength === pageRange.length) {
      break;
    }

    dir = -dir;
  }

  return pageRange;
};

/**
 * Separate pagers for different widths.
 */
export const ResponsivePagers = ({ getLink, totalPages, currentPage }) => {
  return (
    <>
      <Pager
        numberOfLinks={5}
        {...{ totalPages }}
        currentPage={currentPage}
        className="md:hidden"
        getLink={getLink}
      />
      <Pager
        numberOfLinks={15}
        {...{ totalPages }}
        currentPage={currentPage}
        className="hidden md:flex"
        getLink={getLink}
      />
    </>
  );
};

export default Pager;
