import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "next-share";

const ShareLinks = (props: { className?: string }) => {
  const url = typeof window !== "undefined" ? window.location.href : null;
  return (
    <div className={`flex justify-left gap-2 py-2 ${props.className || ""}`}>
      <div className="font-bold">Share</div>
      <FacebookShareButton url={url}>
        <FacebookIcon className="w-5 h-5" />
      </FacebookShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon className="w-5 h-5" />
      </LinkedinShareButton>
      <InstapaperShareButton url={url}>
        <InstapaperIcon className="w-5 h-5" />
      </InstapaperShareButton>
    </div>
  );
};

export default ShareLinks;
