import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useToc = (selector: string, excludeClass?: string) => {
  const [headings, setHeadings] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const domHeadings = Array.from(document.querySelectorAll(selector))
      .filter((element) =>
        excludeClass ? !element.classList.contains(excludeClass) : true
      )
      .map((element) => {
        return {
          id: element.id,
          title: element.getAttribute("data-tocname") || element.textContent,
          type: element.tagName,
        };
      });
    setHeadings(domHeadings);
  }, [selector, router.asPath]);

  return { headings };
};

export default useToc;
