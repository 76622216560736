import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getUrlForCmsAsset } from "@lib/utilities/client-utilities";
import QuoteIcon from "@components/svgs/quote";
import slugify from "slugify";
import ImageWithFallback from "./image-with-fallback";
import VsPageCtaCard from "@components/compare/vs-page-cta-card";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import MailchimpForm from "@components/form/mailchimp-form";

export const getOptions = (
  container = true,
  spacing: "tight" | "normal" = "normal",
  mini = false,
  categories?: ProductCategoryConfigMap
) => {
  const headingSlugs = {};

  /**
   * Applies a unique, readable ID to
   */
  const uniqueId = (seed: string) => {
    let slug = "heading--" + slugify(seed.toLowerCase(), { strict: true });

    let c = 0;
    while (headingSlugs[slug]) {
      slug = slug.split("--")[0] + "--" + c;
      c++;
    }

    headingSlugs[slug] = true;

    return slug;
  };

  const centerWidth = 1152;
  const centerColClass = container ? "max-w-screen-md mx-auto" : "";
  const largeBlockSpacing = spacing === "tight" ? "my-6" : "my-6 sm:my-12";
  const scrollMarginClass = "scroll-mt-[64px]";

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { width, height } = node.data.target.fields.file.details.image;
        const ratio = width / height;

        // Don't resize images if their original size is narrower than the
        // center column. We have a lot of images with text, so any kind of
        // resizing looks bad.
        const imageWidth = width <= centerWidth ? width : centerWidth;

        const { description, title } = node.data.target.fields;

        return (
          <div
            className={`lg:max-w-screen-md xl:max-w-none ${largeBlockSpacing}`}
          >
            <ImageWithFallback
              src={getUrlForCmsAsset(node.data.target, { w: imageWidth })}
              height={Math.round(imageWidth / ratio)}
              width={imageWidth}
              alt={title}
              // We use outline here so that it doesn't resize the image.
              className={`rounded-lg mx-auto outline outline-1 outline-gray-500 outline-offset-8`}
            />
            {description && (
              <div
                className={`py-2 italic mx-auto mt-2 text-gray-600 text-sm`}
                style={{ maxWidth: `${Math.min(imageWidth + 18, 768)}px` }}
              >
                {description}
              </div>
            )}
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const entry = node.data.target;
        const type = entry.sys.contentType.sys.id;

        switch (type) {
          case "blockQuote":
            return (
              <div
                className={`${largeBlockSpacing} relative ${centerColClass}`}
              >
                <QuoteIcon className="absolute top-1 left-0" />
                <div className="pl-10 sm:pl-16">
                  {entry.fields.bodyRichText
                    ? renderRichText(
                        entry.fields.bodyRichText,
                        false,
                        "normal",
                        true
                      )
                    : entry.fields.body}
                  <div className=" text-gray-500 font-medium tracking-tight mt-3 md:mt-5">
                    – {entry.fields.attribution}
                  </div>
                </div>
              </div>
            );
          case "textBlock":
            return (
              <div
                className={`${centerColClass} pt-8 my-8 border-t border-gray-500`}
              >
                {renderRichText(entry.fields.text, false, "normal", true)}
              </div>
            );
          case "inlineBlock": {
            const productCategoryName =
              entry.fields.productCategory?.fields?.name;

            switch (entry.fields.blockType) {
              case "Recommender CTA":
                if (!productCategoryName || !categories[productCategoryName]) {
                  return null;
                }

                return (
                  <div className="my-12">
                    <div className={centerColClass}>
                      <VsPageCtaCard
                        productCategory={categories[productCategoryName]}
                        className="max-w-[300px] mx-auto"
                      />
                    </div>
                  </div>
                );
            }
            break;
          }
          case "emailSignupForm":
            return (
              <div className="bg-gray-100 px-4 py-4 sm:py-12 rounded-xl my-12">
                <h2 className="font-sans mb-4 text-3xl text-center no-toc">
                  {entry.fields.headline}
                </h2>
                {entry.fields.subheading && (
                  <div className="my-6 text-center">
                    {renderRichText(entry.fields.subheading)}
                  </div>
                )}
                <MailchimpForm
                  tags={entry.fields.tags}
                  btnText="Submit"
                  inlineBtn
                />
              </div>
            );
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p
            className={`${centerColClass} ${
              spacing === "tight" ? "mb-4" : ""
            } ${mini ? "text-sm sm:text-base" : ""}`}
          >
            {children}
          </p>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <h2
            className={`font-sans mb-4 text-[2rem] ${centerColClass}`}
            id={uniqueId(node.content[0].value)}
          >
            {children}
          </h2>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <h2
            className={`font-sans mb-4 text-3xl ${centerColClass} ${scrollMarginClass}`}
            id={uniqueId(node.content[0].value)}
          >
            {children}
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3
            className={`font-sans mb-4 text-2xl ${centerColClass} ${scrollMarginClass}`}
            id={uniqueId(node.content[0].value)}
          >
            {children}
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <h3
            className={`font-sans mb-4 text-xl ${centerColClass}`}
            id={uniqueId(node.content[0].value)}
          >
            {children}
          </h3>
        );
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <table
            className={`content-table ${largeBlockSpacing} ${centerColClass}`}
          >
            <tbody>{children}</tbody>
          </table>
        );
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol
            className={`list-decimal pl-6 text-base content-list ${centerColClass}`}
          >
            {children}
          </ol>
        );
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul
            className={`text-base list-disc pl-5 content-list ${centerColClass}`}
          >
            {children}
          </ul>
        );
      },
      [BLOCKS.HR]: (node, children) => {
        return <hr className={`${largeBlockSpacing} ${centerColClass}`} />;
      },
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a href={data.uri} className="underline text-blue-600">
            {children}
          </a>
        );
      },
    },
  };
};

/**
 * Render a rich text field from Contentful.
 *
 * @param text
 *   A rich text field from a Contentful entry.
 * @param container
 *   Whether or not to add a center column container for the text. Full page
 *   content like blog posts and landing pages uses this.
 * @param spacing
 *   Either "tight" or "normal" (default). Normal is best for full page content,
 *   but use tight if you are rendering text in a small container and want less
 *   space between paragraphs and other blocks.
 * @param mini
 *   Does text-sm sm:text-base
 * @returns
 */
export const renderRichText = (
  text,
  container = true,
  spacing: "tight" | "normal" = "normal",
  mini = false,
  categories?: ProductCategoryConfigMap
) =>
  documentToReactComponents(
    text,
    getOptions(container, spacing, mini, categories)
  );
