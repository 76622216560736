import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const usePager = (path: string) => {
  const { query, isReady, asPath } = useRouter();
  const [currentPage, setCurrentPage] = useState<number>();

  useEffect(() => {
    // The path matching helps us get around problems with the "internal"
    // rewrite step.
    if (isReady && asPath.split("?")[0] === path) {
      setCurrentPage(query.page ? parseInt(query.page as string) : 1);
    }
  }, [isReady, query.page]);

  return { currentPage };
};

export default usePager;
